
import { Button } from "@/Components/ui/button"
import { Card, CardFooter, CardHeader, CardTitle } from "@/Components/ui/card"
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/Components/ui/carousel"
import { useProductCollections } from "@/Hooks/CollectionsHook"
import { Link } from "@inertiajs/react"
import { Img } from "react-image"


export default function CollectionPart() {

    const { collections } = useProductCollections();

    return <div className="w-full mt-8 rounded-2xl flex flex-col justify-center items-center shadow-xl shadow-spread-2 bg-[#61677a]">
        <div className="max-w-7xl w-full flex flex-col justify-center items-center gap-x-4 py-8 gap-y-6">
            <div data-aos="fade-up" className="flex flex-col items-center w-full h-[180px] gap-2 justify-center text-white">
                <h1 className="md:text-5xl text-3xl font-semibold">Collections</h1>
                <p className="md:text-3xl text-md font-semibold">Find the best products for you</p>
            </div>
            <Carousel
                opts={{ align: "center", }}
                className="w-full"
            >
                <CarouselContent>
                    {collections?.map((item, index) => (
                        <CarouselItem key={index} className="md:basis-1/3 lg:basis-1/4 basis-1/2 min-w-[300px]">
                            <Card data-aos="fade-up" className="rounded-2xl flex justify-center items-center flex-col"  >
                                <CardHeader>
                                    <CardTitle className="text-center text-2xl font-semibold">{item.name}</CardTitle>
                                </CardHeader>
                                <Img src={item.image_url} className="size-[80%] h-[280px] object-cover" />
                                <CardFooter className="items-center mt-2 justify-center flex">
                                    <Link href={route('home.predesign-products-collections', { collection: item.slug })}>
                                        <Button className="px-6">See Collection</Button>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </CarouselItem>
                    ))}
                </CarouselContent>
                {collections?.length > 3 && <>
                    <CarouselPrevious data-aos="fade-up" iconClass="size-4" />
                    <CarouselNext data-aos="fade-up" iconClass="size-4" />
                </>}
            </Carousel>
        </div>
    </div>
}
